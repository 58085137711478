import React from 'react';
import { Link } from 'react-router-dom';

import Main from '../layouts/Main';

const Index = () => (
  <Main
    description={"Adam Liu's website portfolio. A third year CS undergraduate at UCSD, "
    + 'focusing on full-stack development and machine learning'}
  >
    <article className="post" id="index">
      <header>
        <div className="title">
          <h2 data-testid="heading"><Link to="/">About this site</Link></h2>
          <p>
            A website portfolio built using React.
          </p>
        </div>
      </header>
      <p> Welcome to my website. This site is dedicated to showcase my past
        experience in machine learning and data science, as well as full stack
        development.
        Please feel free to read more <Link to="/about">about me</Link>,
        or you can check out my {' '}
        <Link to="/resume">resume</Link>, {' '}
        <Link to="/projects">projects</Link>, {' '}
        view <Link to="/links">affiliated links</Link>, {' '}
        or <Link to="/contact">contact</Link> me.
      </p>
    </article>
  </Main>
);

export default Index;
