const routes = [
  {
    index: true,
    label: 'Adam Liu',
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Resume',
    path: '/resume',
  },
  {
    label: 'Projects',
    path: '/projects',
  },
  {
    label: 'Link',
    path: '/links',
  },
  {
    label: 'Critique',
    path: '/critique',
  },
  {
    label: 'Media Project',
    path: '/media',
  },
];

export default routes;
